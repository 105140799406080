import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button, TextField, Grid, Typography, Paper } from '@mui/material';

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [eventDetails, setEventDetails] = useState({
    title: '',
    date: '',
    reminder: '',
  });
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [showEventDetails, setShowEventDetails] = useState(false);

  const onDateClick = (value) => {
    setEventDetails({
      title: '',
      date: value.toDateString(),
      reminder: '',
    });
    setShowEventDetails(true);
  };

  const addEvent = () => {
    if (eventDetails.title && eventDetails.date) {
      const newEvent = {
        title: eventDetails.title,
        date: eventDetails.date,
        reminder: eventDetails.reminder,
      };
      setEvents([...events, newEvent]);
      setEventDetails({ title: '', date: '', reminder: '' });
      setShowEventDetails(false);
    }
  };

  const renderEvents = () => {
    return events.map((event, index) => (
      <Paper key={index} elevation={3} className="event">
        <Typography variant="h6">{event.title}</Typography>
        <Typography>Date: {event.date}</Typography>
        <Typography>Reminder: {event.reminder}</Typography>
      </Paper>
    ));
  };

  return (
    <div className="calendar">
      <div className="calendar-header">
        <Typography variant="h4">My Calendar</Typography>
      </div>

      <Grid container className="calendar-display" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <Calendar
            value={currentMonth}
            onChange={setCurrentMonth}
            onClickDay={onDateClick}
            className="custom-calendar"
            tileClassName="custom-tile"
            calendarType="US"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {showEventDetails ? (
            <Paper elevation={3} className="event-creation">
              <Typography variant="h6">Create Event</Typography>
              <TextField
                fullWidth
                label="Event Title"
                variant="outlined"
                value={eventDetails.title}
                onChange={(e) => setEventDetails({ ...eventDetails, title: e.target.value })}
              />
              <TextField
                fullWidth
                variant="outlined"
                disabled
                value={eventDetails.date}
              />
              <TextField
                fullWidth
                label="Reminder (e.g., 1 hour before)"
                variant="outlined"
                value={eventDetails.reminder}
                onChange={(e) => setEventDetails({ ...eventDetails, reminder: e.target.value })}
              />
              <Button variant="contained" color="primary" onClick={addEvent}>
                Add Event
              </Button>
            </Paper>
          ) : (
            <Paper elevation={3} className="event-list">
              {renderEvents()}
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default MyCalendar;
