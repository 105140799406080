import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import all your page components
import Home from './pages/Home/Home.jsx';
import About from './pages/Home/About.jsx';
import Contact from './pages/Home/Contact.jsx';
import Login from './pages/Home/Login.jsx';
import Register from './pages/Home/Register.jsx';

//Admin Imports
import Admin from './pages/Admin/Admin.jsx';
import AdminChat from './pages/Admin/AdminChat.jsx';
import AdminCustomer from './pages/Admin/AdminCustomer.jsx';
import AdminCustomerView from './pages/Admin/AdminCustomerView.jsx';
import AdminDemoPage from './pages/Admin/AdminDemoPage.jsx';
import AdminEmail from './pages/Admin/AdminEmail.jsx';
import AdminOrder from './pages/Admin/AdminOrder.jsx';
import AdminOrderView from './pages/Admin/AdminOrderView.jsx';
import AdminProductAdd from './pages/Admin/AdminProductAdd.jsx';
import AdminCourseGrid from './pages/Admin/AdminCourseGrid.jsx';
import AdminCourseList from './pages/Admin/AdminCourseList.jsx';
import AdminReview from './pages/Admin/AdminReview.jsx';
import AdminVenderList from './pages/Admin/AdminVenderList.jsx';
import AdminVenderView from './pages/Admin/AdminVenderView.jsx';
import AdminNewProject from './pages/Admin/AdminNewProject.jsx';
import LearnerProgress from './pages/Admin/AdminLearnerProgress.jsx';
import Forgot from './pages/Forgot';
import NotFound from './pages/NotFound';
import ComingSoon from './components/ComingSoon.jsx';
//User Imports
import UserProfile from './pages/UserProfile';

// Application 
// import Default from './pages/Default';
// import DefaultCourseOne from './pages/DefaultCourseOne';

// import * as serviceWorker from './serviceWorker';

const App = () => {

  return (
    <Router basename={'/'}>
    <Routes>
      {/* Home Routes */}
      <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
      <Route path={`${process.env.PUBLIC_URL}/about`} element={<About />} />
      <Route path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} />
      <Route path={`${process.env.PUBLIC_URL}/login`} element={<Login />} />
      <Route path={`${process.env.PUBLIC_URL}/register`} element={<Register />} />
      <Route path={`${process.env.PUBLIC_URL}/forgot`} element={<Forgot />} />

      {/* User Routes */}
      <Route path={`${process.env.PUBLIC_URL}/user-profile`} element={<UserProfile />} />

      {/* Admin Routes */}

      <Route path={`${process.env.PUBLIC_URL}/admin`} element={<Admin />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-chat`} element={<AdminChat />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-customer`} element={<AdminCustomer />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-customerview`} element={<AdminCustomerView />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-demopage`} element={<AdminDemoPage />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-email`} element={<AdminEmail />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-order`} element={<AdminOrder />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-orderview`} element={<AdminOrderView />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-productadd`} element={<AdminProductAdd />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-productgrid`} element={<AdminCourseGrid />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-productlist`} element={<AdminCourseList />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-review`} element={<AdminReview />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-venderlist`} element={<AdminVenderList />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-venderview`} element={<AdminVenderView />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-newproject`} element={<AdminNewProject />} />
      <Route path={`${process.env.PUBLIC_URL}*`} element={<NotFound />} />
      <Route path={`${process.env.PUBLIC_URL}/admin-learner-progress`} element={<LearnerProgress />} />
      {/*  adapt the following routes as needed */}
      {/* <Route path={`${process.env.PUBLIC_URL}/default`} element={<Default />} />
      <Route path={`${process.env.PUBLIC_URL}/default-courseone`} element={<DefaultCourseOne />} /> */}
    </Routes>
  </Router>
  )
}

export default App;