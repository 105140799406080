import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faChevronRight, faEnvelope, faBell, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import Logo from '../assets/images/logos/tks360-logo.png';

//styles
import '../assets/css/styles.css';
import '../assets/css/default.css';

const UserNav = ({ divClass, color = 'light' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const navClass = isOpen ? 'show' : '';
  let colorClass = '';
  if (color === 'dark') {
    colorClass = 'text-white';
  }

  const iconStyle = {
    fontSize: '24px', // Adjust the size as needed
  };

  const logoStyle = {
    width: '195px', // Adjust the size as needed
    height: '130px', // Adjust the size as needed
    verticalAlign: 'middle', // Center vertically
  };
    
  return (
    <div className={`header-wrapper shadow-xss ${divClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-9 navbar pt-0 pb-0">
            <Link to="/" style={{ display: 'inline-block' }}>
              <img className="navbar-brand" src={Logo} alt="tks360-logo" style={logoStyle} />
            </Link>
            <button className="navbar-toggler" onClick={toggleOpen}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${navClass}`} id="navbarNavDropdown">
              <Navbar expand="lg" className="dropdown-navbar slide-navmenu nav-menu">
                <Navbar id="basic-navbar-nav" className="w-100 d-block">
                  <Nav className={`${colorClass}`}>
                    <NavDropdown title="Home" id="basic-nav-dropdown">
                      <NavDropdown.Item href="/home">Home</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="About Us" id="basic-nav-dropdown">
                      <NavDropdown.Item href="/about">About Us</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Contact Us" id="basic-nav-dropdown">
                      <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar>
              </Navbar>
            </div>
          </div>
          <div className="col-lg-3 text-right d-none d-lg-block">
          <Link to="#" className={`float-right d-none d-lg-block text-center mt-4 ml-4 text-grey-600 ${colorClass}`}>
    <FontAwesomeIcon icon={faUserCircle} style={iconStyle} /> {/* User profile icon */}
  </Link>
  <Link to="#" className={`float-right text-center mt-4 ml-4 text-grey-600 position-relative ${colorClass}`}>
    <FontAwesomeIcon icon={faEnvelope} style={iconStyle} /> {/* Envelope icon */}
  </Link>
  <Link to="#" className={`float-right text-center mt-4 ml-4 text-grey-600 position-relative ${colorClass}`}>
    <FontAwesomeIcon icon={faBell} style={iconStyle} /> {/* Bell icon */}
  </Link>
  
</div>
        </div>
      </div>
    </div>
  );
}

export default UserNav;
