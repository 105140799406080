import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

const AdminSidebar = () => {
  useEffect(() => {
    const elements = document.querySelectorAll('.has-droupdown > a');
    elements.forEach(element => {
      element.onclick = function () {
        this.parentElement.querySelector('.submenu').classList.toggle('active');
        this.classList.toggle('open');
      };
    });
  }, []);

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark scroll-bar" id="accordionSidebar">
      <div className="sidebar-brand d-flex align-items-start justify-content-start" href="/admin">
        <span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-md logo-text mt-3 mb-0 text-capitalize lh-1">
          Learning Content
        </span>
        <button className="ms-auto mt-2 d-lg-none" id="sidebarClose">
          <i className="ti-close text-white font-sm text-grey-100 "></i>
        </button>
      </div>


      <div className="sidebar-heading font-semibold text-xl"></div>


      <NavLinkItem to="/admin" iconClass="feather-pie-chart" text="Dashboard" />
      <DropdownLink title="Projects" iconClass="feather-video">
        <NavLinkItem to="/admin-productlist" text="Course List" />
        <NavLinkItem to="/admin-productgrid" text="Course Grid" />
        <NavLinkItem to="/admin-productadd" text="Add Course" />
      </DropdownLink>
      <DropdownLink title="Training" iconClass="feather-calendar">
      <NavLinkItem to="" text="Calendar" />
      </DropdownLink>
      <DropdownLink title="Users" iconClass="feather-video">
       <section className='navtitles  text-white '> Manage Users </section>
        <NavLinkItem iconClass="" to="" text="Users" />
        <NavLinkItem iconClass="" to="" text="Roles" />
        <NavLinkItem iconClass="" to="" text="Departments" />
        <NavLinkItem iconClass="" to="" text="Groups" />
        <NavLinkItem iconClass="" to="" text="Sign up Profiles" />
      </DropdownLink>
      <DropdownLink title="Reports" iconClass="feather-video">
        <section className='navtitles text-white '>Reports</section>
        <section className='navtitles text-white pt-3'>BY LEARNERS</section>
        <NavLinkItem iconClass="" to="/admin-learner-progress" text="Learner Progress" />
        <NavLinkItem iconClass="" to="" text="Learner Reports" />
        <NavLinkItem iconClass="" to="" text="Department Reports" />
        <NavLinkItem iconClass="" to="" text="Group Reports" />
        <section className='navtitles text-white pt-3'>BY COURSES</section>
        <NavLinkItem iconClass="" to="" text="Courses" />
        <NavLinkItem iconClass="" to="" text="Modules" />
        <NavLinkItem iconClass="" to="" text="Role-Play, Survey, Quizzes" />
        <NavLinkItem iconClass="" to="" text="Quizzes" />
        <NavLinkItem iconClass="" to="" text="Answer Breakdown" />
        <NavLinkItem iconClass="" to="" text="Task" />
        <NavLinkItem iconClass="" to="" text="Learning Tracks" />
        <section className='navtitles text-white pt-3'>BY TRAININGS</section>
        <NavLinkItem iconClass="" to="" text="Tranings" />
        <section className='navtitles text-white pt-3'>ON-THE-JOB TRAININGS</section>
        <NavLinkItem iconClass="" to="" text="Assessment Record" />
        <section className='navtitles text-white pt-3'>OTHER</section>
        <NavLinkItem iconClass="" to="" text="Leaderboard" />
        <NavLinkItem iconClass="" to="" text="Active Users" />
        <NavLinkItem iconClass="" to="" text="Certificates" />
      </DropdownLink>
      <DropdownLink title="Course Questions" iconClass="feather-video">
      </DropdownLink>
      <DropdownLink title="On-The-Job Training" iconClass="feather-video">
      <section className='navtitles text-white pt-3'>On-The-Job Training</section>
      <NavLinkItem iconClass="" to="" text="OJT Sessions" />
      <NavLinkItem iconClass="" to="" text="Certificates" />
      </DropdownLink>
      <DropdownLink title="Appraisal" iconClass="feather-video">
      <section className='navtitles text-white pt-3'>Performance Appraisal</section>
      <NavLinkItem iconClass="" to="" text="Performance Reviews" />
      <NavLinkItem iconClass="" to="" text="Compentency Profiles" />
      <NavLinkItem iconClass="" to="" text="Competencies" />
      <NavLinkItem iconClass="" to="" text="Rating Scales" />
      </DropdownLink>
      <DropdownLink title="Newsfeed" iconClass="feather-video">
      <section className='navtitles text-white pt-3'>Newsfeed</section>
      <NavLinkItem iconClass="" to="" text="News" />
      <NavLinkItem iconClass="" to="" text="Banners" />
      </DropdownLink>
      <DropdownLink title="Settings" iconClass="feather-video">
      <section className='navtitles text-white pt-3'>Settings</section>
      <NavLinkItem iconClass="" to="" text="Main" />
      <NavLinkItem iconClass="" to="" text="Branding" />
      <NavLinkItem iconClass="" to="" text="Additional Options" />
      <NavLinkItem iconClass="" to="" text="Billing" />
      </DropdownLink>
      {/*Repeat DropdownLink and NavLinkItem for other menu items */}
      {/* ... other sidebar items ... */}

     {/*Footer or additional sections */}
      <div className="mt-3"></div>
     {/* <div className="sidebar-card d-lg-flex mt-auto p-4">
        <p className="text-center mb-3 font-xsss text-grey-100 fw-500">
          Become a <b>pro</b> <br /> Get more <b>features</b>
        </p>
        <NavLink to="/admin" className="bg-success text-white rounded-xl btn-cart w125 d-inline-block text-center font-xsssss p-3 fw-700 ls-3">
          ANALYTICS
        </NavLink>
  </div> */}
    </ul>
  );
};

const NavLinkItem = ({ to, iconClass, text }) => (
  <li className="nav-item">
    <NavLink className="navi-link" to={to}>
      <i className={`${iconClass} mr-2`}></i>
      <span>{text}</span>
    </NavLink>
  </li>
);

const DropdownLink = ({ title, iconClass, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className={`nav-item has-droupdown ${isOpen ? 'open' : ''}`}>
      <Link to="#" className="navi-link" onClick={toggleDropdown}>
        <i className={`${iconClass} mr-2 dropdown-toggle`}></i>
        <span>{title}</span>
      </Link>
      <ul className={`submenu ${isOpen ? 'active' : ''}`}>
        {children}
      </ul>
    </li>
  );
};

export default AdminSidebar; 
