import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Slider from 'react-slick';
import '../../assets/css/styles.css'; //css

//image imports
import img1 from '../../../src/assets/images/TKS360-contact/istockphoto-online-classes.jpg';
import img2 from '../../../src/assets/images/TKS360-contact/student.png';
import img3 from '../../../src/assets/images/TKS360-contact/istockphoto-training-2.jpg';
import img4 from '../../../src/assets/images/TKS360-contact/istockphoto-training.jpg';

const brandList = [
  { bimg: img1},
  { bimg: img2 },
  { bimg: img3},
  { bimg: img4},
];



const Contact = () => {
  
  const brandsettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  // line 67 is a placeholder,will change later
  return (
    <>
      <Header />
      

      <div className="map-wrapper pb-2 pb-lg--5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center pt-7">
              <h1 className="">Contact us</h1>
              <p className="ptext">"Experience the power of our eLearning solutions firsthand! <br></br> Register for a customized demonstration with one of our knowledgeable eLearning specialists and witness our solutions in action."</p>
            </div>
        </div>
        <div className="brand-wrapper pb-7 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Slider {...brandsettings}>
                {brandList.map((value, index) => (
                  <div key={index} className="text-center">
                    <img
                      src={value.bimg}
                      alt="avatar"
                      className=" contact-img  ml-auto mr-auto"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 pb-lg--7 pb-5 pt-0 mb-3">
              <div className="row">
                <div className="col-lg-4 col-md-4 xs-mb-2">
                  <div className="iconcenter card shadow-xss border-0 p-5 rounded-lg shadow-lg">
                    <span className="btn-round-xxxl alert-success items-center">
                      <i className="feather-slack text-success font-xl"></i>
                    </span>
                    <h2 className="fw-700 font-sm mt-4 mb-3 text-grey-900 text-center">
                      tks360 LMS
                    </h2>
                    <p className="font-xsss text-grey-500 fw-500 mb-3 text-center">
                    Learn the strategies for successfully implementing eLearning initiatives
                    or enhancing corporate training efficiency through tks360 LMS.                      
                    </p>
                    <a
                      href="/contact"
                      className="fw-700 font-xsss text-primary text-center pt-4"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 xs-mb-2">
                  <div className="iconcenter card shadow-xss border-0 p-5 rounded-lg shadow-lg">
                    <span className="btn-round-xxxl alert-primary">
                      <i className="feather-trending-up  text-primary font-xl"></i>
                    </span>
                    <h2 className="fw-700 font-sm mt-4 mb-3 text-grey-900 text-center">
                      Sales team
                    </h2>
                    <p className="font-xsss text-grey-500 fw-500 mb-3 text-center ">
                      Got question about what we offer? Just drop them in our contact form, and a friendly sales manager will hit you up soon with a quote.
                    </p>
                    <a
                      href="/contact"
                      className="fw-700 font-xsss text-primary text-center pt-4"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 xs-mb-2">
                  <div className="iconcenter card shadow-xss border-0 p-5 rounded-lg shadow-lg">
                    <span className="btn-round-xxxl alert-danger">
                      <i className="feather-users text-danger font-xl"></i>
                    </span>
                    <h2 className="fw-700 font-sm mt-4 mb-3 text-grey-900 text-center">
                      Become a tks360 partner
                    </h2>
                    <p className="font-xsss text-grey-500 fw-500 mb-3 text-center">
                      Become a valued partner for our Learning Management System (LMS) and unlock a world of collaborative opportunities and benefits. 
                      Join us in transforming education together!
                    </p>
                    <a
                      href="/contact"
                      className="fw-700 font-xsss text-primary text-center"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-10 mt-10'>
            <div className="contact-wrap bg-white shadow-lg rounded-lg position-relative">
                <h1 className="text-grey-900 fw-700 display3-size mb-5 lh-1 text-center">
                  Contact us
                </h1>
                <form action="#">
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control style2-input bg-color-none text-grey-700"
                          defaultValue="Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className=" form-group mb-3">
                        <input
                          type="text"
                          className="form-control style2-input bg-color-none text-grey-700"
                          defaultValue="Email"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group mb-3 md-mb-2">
                        <textarea className="w-100 h125 style2-textarea p-3 form-control">
                          Message
                        </textarea>
                      </div>
                      <div className="col-lg-12 col-md-12 text-left mt-3 float-left md-mb-2 vstack">
                        <div className="form-group">
                        <input
                          type="checkbox"
                          className="form-check-input mt-2"
                          id="exampleCheck1"/>
                        <label
                          className="form-check-label font-xsss text-grey-500 fw-500"
                          htmlFor="exampleCheck1">
                          
                          {' '}
                          <a href="/contact" className="text-black fw-600">
                            Marketing Emails
                          </a>
                        </label>
                        </div>
                        <div className="form-group">
                        <input
                          type="checkbox"
                          className="form-check-input mt-2"
                          id="exampleCheck1"/>
                        <label
                          className="form-check-label font-xsss text-grey-500 fw-500"
                          htmlFor="exampleCheck1">
                          
                          {' '}
                          <a href="/contact" className="text-black fw-600">
                            News & Updates
                          </a>
                        </label>
                        </div>
                        <div className="form-group">
                        <input
                          type="checkbox"
                          className="form-check-input mt-2"
                          id="exampleCheck1"/>
                        <label
                          className="form-check-label font-xsss text-grey-500 fw-500"
                          htmlFor="exampleCheck1">
                          
                          {' '}
                          <a href="/contact" className="text-black fw-600">
                            Production Process
                          </a>
                        </label>
                        </div>
                        
                          <div className="form-group">
                        <input
                          type="checkbox"
                          className="form-check-input mt-2"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label font-xsss text-grey-500 fw-500"
                          htmlFor="exampleCheck1"
                        >
                          I agree to the term of this{' '}
                          <a href="/contact" className="text-black fw-600">
                            Privacy Policy
                          </a>
                        </label>
                        </div>
                      </div>
                    
                      <div className="form-group ">
                        <a
                          href="/contact"
                          className="rounded-lg style1-input float-right bg-current text-white text-center font-xss fw-500 border-2 border-0 p-0 w175  "
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;