import AdminSidebar from '../../components/AdminSidebar';
import AdminTopNav from '../../components/AdminTopNav';
import AdminFooter from '../../components/AdminFooter';

const AdminProductAdd = () => {
    return (
        <> 
            <div id="wrapper">   
                <AdminSidebar />

                
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <AdminTopNav />

                        <div className="container px-3 py-4">
                            <div className="row">
                                
                            </div>
                        </div>
                        
                    <AdminFooter />

                    </div>
                </div>

            </div>
        </> 
    );
}

export default AdminProductAdd;