import React from "react";
import CircularWithValueLabel from "./CircularProgress";
import 'tailwindcss/tailwind.css'; // Make sure you've imported the Tailwind CSS styles


/*const StatusTracker = ({ trained, passed, failed, inProgress, notStarted, overdue }) => {
  // Helper function to generate the appropriate styling for each category
  const circleStyle = (percentage) => `relative text-xs font-semibold text-center p-4 w-24 h-24 rounded-full border border-gray-300 ${percentage > 0 ? 'bg-blue-100' : 'bg-gray-100'}`;

  return (
    <div className="flex justify-center grid-cols-6 gap-4">
      <div className={circleStyle(trained)}>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="text-blue-600">{trained}%</div>
          <div>Trained</div>
        </div>
      </div>
      <div className="flex col-1">
      <CircularWithValueLabel>
        <div className="text-center">{passed}</div>
        </CircularWithValueLabel>
        <div className="text-center text-sm">Passed</div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-center">{failed}</div>
        <div className="text-center text-sm">Failed</div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-center">{inProgress}</div>
        <div className="text-center text-sm">In Progress</div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-center">{notStarted}</div>
        <div className="text-center text-sm">Not Started</div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-center">{overdue}</div>
        <div className="text-center text-sm">Overdue</div>
      </div>
    </div>
  );
};

export default StatusTracker; */

// Assume 'percentage' is a value between 0 and 100
const CircleProgress = ({ percentage }) => {
  // Define the size and stroke of the SVG circle
  const size = 100;
  const strokeWidth = 8;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;

  // Calculate the stroke dash offset
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <svg
      width={size}
      height={size}
      className="text-blue-600"
    >
      <circle
        stroke="currentColor"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: offset }}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        className="text-xs font-semibold"
      >
        {percentage}%
      </text>
    </svg>
  );
};

const StatusItem = ({ label, value }) => (
  <div className="flex items-center justify-between">
    <div className="text-sm text-gray-500">{label}</div>
    <div className="font-semibold">{value}</div>
  </div>
);

const StatusTracker = ({ trained, passed, failed, inProgress, notStarted, overdue }) => (
  <div className="grid grid-cols-6">
    <div className="shrink-0 col-span-2">
      <CircleProgress percentage={trained} />
    </div>
    <div className="col-span-2">
      <StatusItem label="Passed" value={passed} />
      </div>
      <div className="col-span-2">
      <StatusItem label="Failed" value={failed} />
      </div>
      <div className="col-span-2">
      <StatusItem label="In Progress" value={inProgress} />
      </div>
      <div className="col-span-2">
      <StatusItem label="Not Started" value={notStarted} />
      </div>
      <div className="col-span-2">
      <StatusItem label="Overdue" value={overdue} />
      </div>
    </div>
);

export default StatusTracker;
