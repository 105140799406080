

const Logo = ({ imageUrl, altText, width, height }) => {
  return (
    <div className="logo-container">
      <img src={imageUrl} alt={altText} width={width} height={height} />
    </div>
  );
};

export default Logo;
