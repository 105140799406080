import React from 'react';
import '../assets/css/comingSoon.css'; // You can create a separate CSS file and import it here for styling
//import Home from '../pages/Home/Home.jsx';
 import Tks360Logo from '../assets/images/logos/newlogo.png' 

const ComingSoon = () => {
  return (
    <div className="coming-soon-overlay">
    <div className="coming-soon-content">
         
        <img src={Tks360Logo} alt="tks360" ></img>
        <br />
        <p className='coming-soon-h1'>Coming Soon...</p>
        <br />
        <p>We're working on something awesome!</p>
    </div>
    </div>
  );
};

export default ComingSoon;

