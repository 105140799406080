import React, { useState } from "react";

import FilterBar from "../../components/FilterBar";
import LearnerTable from "../../components/AdminLearnerTable"
import CircularWithValueLabel from "../../components/CircularProgress";
import AdminSidebar from '../../components/AdminSidebar';
import AdminTopNav from '../../components/AdminTopNav';
import AdminFooter from '../../components/AdminFooter';
import StatusTracker from "../../components/StatusTracker";



const learnersData = [
  // Populate this array with your data objects
  {
    id: 1,
    fullName: "Kumail Hassan",
    department: "Department",
    trained: 0.0,
    enrollments: 2,
    Passed: 1,
    Failed: 0,
    Progress: 50,
    NotStarted: 0,
    Overdue: 0,
    },
    // ... other data fields
 
  // ... more learner objects
];

const App = () => {
  return (
    
    <div id="wrapper">
      <AdminSidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
        <AdminTopNav />
      <section className="">
    <h2>Learner Progress</h2>
      </section>
      <div className="filter">
      <FilterBar/>
      </div>
      
      <StatusTracker
        trained={9}
        passed={35}
        failed={2}
        inProgress={117}
        notStarted={250}
        overdue={196}
      />
    <div className="container mx-auto px-4 sm:px-8">
      <LearnerTable learners={learnersData} />
      
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
