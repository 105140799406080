import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Logo from './Logo.jsx';
//import Tks360Logo from '../assets/images/logos/tks360-logo.png'
import Tks360Logo from '../assets/images/logos/newlogo.png'

//possible font to be added later
// import { inter } from '@/app/ui/fonts';
//{`${inter.className} antialiased container`}

//styles
import '../assets/css/styles.css'
import '../assets/css/default.css'

//test
const Header = ({ divClass, color = 'light' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const navClass = isOpen ? 'show' : '';
  let colorClass = '';
  if (color === 'dark') {
    colorClass = 'text-white';
  }

  return (
    <div className={`header-wrapper shadow-xss ${divClass}`}>
      <div className="container antialiased">
        <div className="row">
          <div className="col-lg-9 navbar pt-0 pb-0">
            <Link to="/" className='mt-3'>
              <Logo
                imageUrl={Tks360Logo}
                altText="tks360 Logo"
                width={150}
                height={50}
              />
            </Link>
            <button className="navbar-toggler" onClick={toggleOpen}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${navClass}`}
              id="navbarNavDropdown"
            >
              <Navbar
                expand="lg"
                className="dropdown-navbar slide-navmenu nav-menu"
              >
                <Navbar id="basic-navbar-nav" className="w-100 d-block">
                  <Nav className={`${colorClass} me-auto`}>
                    <Nav.Link content='Home' href='/' className='p-1 m-3'>
                      Home
                    </Nav.Link>
                    <Nav.Link content='About Us' href='/about' className='p-1 m-3'>
                      About Us
                    </Nav.Link>
                    <Nav.Link content='Contact Us' href='/contact' className='p-1 m-3'>
                      Contact Us
                    </Nav.Link>
                  </Nav>
                </Navbar>
              </Navbar>
            </div>
          </div>
          <div className="col-lg-3 text-right d-none d-lg-block">
            <Link
              to="/login"
              className={`login float-right text-center mt-4 ml-3 text-grey-600 position-relative ${colorClass}`}
            >
              <FontAwesomeIcon icon={faChevronRight} className='faChevronRight' />
              <span className="font-xss fw-500 d-block lh-1">Login</span>
              {/* <span className="icon-count bg-current">2</span> */}
            </Link>
            <Link
              to="/register"
              className={`register float-right text-center mt-4 ml-3 text-grey-600 position-relative ${colorClass}`}
            >
              <FontAwesomeIcon icon={faChevronLeft} className='faChevronRight' />
              <span className="font-xss fw-500 d-block lh-1">Register</span>
              {/* <span className="icon-count bg-current">2</span> */}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
