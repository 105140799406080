import { useState, useRef } from 'react';
import CreateFolderPopup from '../../components/CreateFolderPopup.jsx';
import AdminSidebar from '../../components/AdminSidebar.jsx';
import AdminTopNav from '../../components/AdminTopNav.jsx';
import AdminFooter from '../../components/AdminFooter.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faEllipsis, faArrowDown, faGear, faBook } from '@fortawesome/free-solid-svg-icons';

//Css imports
import "../../assets/css/newProject.css";

const AdminNewProject = () => {
  const [courseName, setCourseName] = useState('');
  const fileUpload = useRef(null);

  const handleChange = (event) => {
    setCourseName(event.target.value);
  };

  const handleClick = (event) => {
    fileUpload.current.click();
  }

  {/**Create Folder */}
  const [folders, setFolders] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const handleClose = (event) => {
    console.log("test");
    setShowPopup(false);
    console.log(showPopup);
  };

  const handleShow = (event) => {
    console.log("test");
    setShowPopup(true);
  };

  const handleCreateFolder = (folderName) => {
    const newFolder = {
      id: Math.random(), 
      name: folderName,
      createdAt: new Date().toLocaleString(), 
    };
    setFolders([...folders, newFolder]);
    handleClose();
    console.log('Creating folder:', folderName);
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    console.log('Uploaded files:', files);
  };

  //test
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleUserPortalClick = () => {
    console.log('Opening User Portal');
  };

  const handleMyProfileClick = () => {
    console.log('Opening My Profile');
  };

  const handleLogoutClick = () => {
    console.log('Logging out');
  };

  return (
    <>
    {/**test */}
    <div className="initials-button-container">
      <button className="initials-button" onClick={toggleMenu}>
        User Initials {/* Replace with actual user initials */}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <button onClick={handleUserPortalClick}>User Portal</button>
          <button onClick={handleMyProfileClick}>My Profile</button>
          <button onClick={handleLogoutClick}>Log Out</button>
        </div>
      )}
    </div>

      <AdminTopNav />
      <div id="wrapper">
        <AdminSidebar />
        <div id="content-wrapper" className="d-flex flex-column" >
          <div id="content">
                 
            <div className="container px-3 py-4">
              <div className="col-lg-12 ">
                <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                  <div className="card-body d-block ">
                    <div className="new-project-container">
                      <div className="new-project-text-wrapper">
                        <div className="text-row">
                          <div className="text-wrapper">
                            <input
                              type='text'
                              placeholder='New Project (John Doe)'
                              id='courseName'
                              name='courseName'
                              onChange={handleChange}
                              value={courseName} // Bind value to courseName state
                              className='new-project-text font-lg'
                            />
                          </div>
                        </div>
                        <div className='icons-wrapper'>
                          <ul className='icon-list-container'>
                            <li className="icon-list-item">
                            <div className='icon-container icon-list-item-initials'>
                              <div className="icon-underlay icon-underlay-style-default">
                                <div className="icon-initials icon-initials-style-default icon-initials-confirmed">
                                <div className="icon-initials">
                                  <h3>AB</h3>
                                </div>
                                </div>
                              </div>
                            </div>
                            </li>
                          </ul>
                          <div className='add-collaborators-container'>
                            <FontAwesomeIcon icon={faCirclePlus} className='add-collaborators' />
                          </div>
                          <button href="#">
                            <FontAwesomeIcon icon={faEllipsis} className='manage-project ml-2' />
                          </button>
                        </div>
                      </div>
                  
                    </div>
                    
                    <div className="button-wrapper">
                        <CreateFolderPopup
                          show={showPopup}
                          handleClose={handleClose}
                          handleCreate={handleCreateFolder}
                        />
                      <button onClick={handleShow} className="create-folder-button">
                        
                    <div className="create-folder-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512"><path d="M512 416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24V312h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V200c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/></svg>
                        </div>
                      </button>

                      <div className="btn-container">
                        <div className="upload-button">
                          <input ref={fileUpload} type="file" title="" multiple="" className="upload-file-input btn-spacing-default"accept=".ppt, .pptx, .pdf, .mp4, .mp3, .ismpkg, .isqmpkg, .issmpkg, .zip, .flv, .doc, .docx, .xls, .xlsx"
                          onChange={handleFileUpload} 
                          />
                          <button onClick={handleClick} className='btn-spacing-default btn-size-medium btn-size-small'>
                            
                            <div className="upload-btn upload-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>
                              </svg>
                            </div>
                            <div className="upload-icon upload-text">
                              <h3 className="text">
                                Upload
                              </h3>
                            </div>
                          </button>
                        </div>
                      <button className="create-button btn-spacing-default btn-size-medium btn-size-small">
                        <div className="create-icon-wrapper">
                          <svg className='create-svg' xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                          </svg>
                        </div>
                        <div className="text-wrapper">
                          <h3 className="text">
                            Create
                          </h3>
                        </div>
                      </button>
                      </div>
                    </div>

                    <form className="contact_form"name="contact_form" action="#"method="post" noValidate="">
                      <div className="row mt-4">
                        <div className="col-md-2">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label">
                              Title<FontAwesomeIcon icon={faArrowDown} className='faArrowDown'/>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label">
                              Type
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label">
                              Enrollments
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label text-gray-500">
                              Created by
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label text-gray-500">
                              Added
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label ">
                              <FontAwesomeIcon icon={faGear} className='display-columns text-gray-500'/>
                            </label>
                          </div>
                        </div>
                        <div className="line"></div>

                        {/* Rendering folders */}
                        <div>
                          {folders.map((folder) => (
                            <div key={folder.id}>
                              <p>Name: {folder.name}</p>
                              <p>Created at: {folder.createdAt}</p>
                              {/* add additional functionality for each folder */}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className='outer-wrapper'>
                          <div className='centered-wrapper'>
                            <div className='learning-materials-wrapper'>
                              {/* Your content */}
                              <FontAwesomeIcon icon={faBook} className='faBook'/>
                              <div className='learning-text-wrapper'>
                                <h3>Add learning projects</h3>
                                <p>Create, <a href='#' className='text-decoration-underline'>upload</a> or drag your content here</p>
                              </div>
                              <p className='supported-formats'>Supported formats: ppt, pptx, pdf, mp4, mp3, ismpkg, <br /> isqmpkg, issmpkg, zip, flv, doc, docx, xls, xlsx</p>
                            </div>
                          </div>
                        </div>
                    </form>
                  </div>
                </div>
                <AdminFooter />
              </div>
            </div>
          </div>
       </div>
      </div>
      
    </>
  );
}

export default AdminNewProject;