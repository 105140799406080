import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Slider from 'react-slick';
import '../../assets/css/styles.css';
import '../../assets/css/home.css';
import ComingSoon from '../../components/ComingSoon';


//images
import PopularCourses from "../../../src/assets/images/istocktrial/PopularCourses.jpg";
import VideoThumbnail from "../../assets/images/demo-video-thumbnail.jpg";

const brandList = [
  { bimg: 'mysql.png' },
  { bimg: 'html.png' },
  { bimg: 'css.png' },
  { bimg: 'js.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
];

const iconList = [
  {
    title: 'Blazing Performance',
    des: 'Praesent porttitor nunc vitae lacus vehicula, nec mollis eros congue.',
    icon: 'feather-layers',
    color: 'text-warning',
    bgcolor: 'alert-warning',
  },
  {
    title: 'Free Updates',
    des: 'Praesent porttitor nunc vitae lacus vehicula, nec mollis eros congue.',
    icon: 'feather-lock',
    color: 'text-secondary',
    bgcolor: 'alert-secondary',
  },
  {
    title: '24/7 Supports',
    des: 'Praesent porttitor nunc vitae lacus vehicula, nec mollis eros congue.',
    icon: 'feather-box',
    color: 'text-success',
    bgcolor: 'alert-success',
  },
  {
    title: 'Improve Workflow',
    des: 'Praesent porttitor nunc vitae lacus vehicula, nec mollis eros congue.',
    icon: 'feather-box',
    color: 'text-success',
    bgcolor: 'alert-success',
  },
  {
    title: 'Reduce Hiring Costs',
    des: 'Praesent porttitor nunc vitae lacus vehicula, nec mollis eros congue.',
    icon: 'feather-box',
    color: 'text-secondary',
    bgcolor: 'alert-secondary',
  },
  {
    title: 'Upskill your employees',
    des: 'Praesent porttitor nunc vitae lacus vehicula, nec mollis eros congue.',
    icon: 'feather-layers',
    color: 'text-warning',
    bgcolor: 'alert-warning',
  },
];

const courseList = [
  {
    imageUrl: 'demo-video-thumbnail.jpg',
    title: 'Professional JavaScript Developer Full Stack Course ',
    price: '400',
    tag: 'JavaScript',
    lesson: '60 ',
    status: 'alert-warning text-warning',
  },
  {
    imageUrl: 'course.png',
    title: 'Professional Backend Developer MySql Course ',
    price: '200',
    tag: 'MySQL',
    lesson: '30 ',
    status: 'alert-danger text-danger',
  },
  {
    imageUrl: 'course.png',
    title: 'Oracle Java Programming Masterclass for Developers',
    price: '120',
    tag: 'Java',
    lesson: '14 ',
    status: 'alert-success text-success',
  },
  {
    imageUrl: 'course.png',
    title: 'HTML & CSS Design Course ',
    price: '150',
    tag: 'CSS',
    lesson: '45 ',
    status: 'alert-danger text-danger',
  },
  {
    imageUrl: 'course.png',
    title: 'FullStack Python Course ',
    price: '450',
    tag: 'Python',
    lesson: '50 ',
    status: 'alert-danger text-danger',
  },
  {
    imageUrl: 'course.png',
    title: 'Time Management & Policies ',
    price: '50',
    tag: 'Python',
    lesson: '25 ',
    status: 'alert-warning text-warning',
  },
];

const Home = () => {
    const brandsettings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 6,
      centerMode: false,
      variableWidth: false,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };

    return (
      <>
        <Header className="shadow-none" />

        <section className="banner-wrapper">
          <div className="container">
            <div className="row">
              <div className="hero-wrapper">
                <h2 className="hero-header">
                  The Best LMS to upskill and certify your employees
                </h2>
                <ul>
                  <li><h4 className="hero-paragraph">
                  Learning Management System
                </h4></li>
                <li><h4 className="hero-paragraph">
                Compliance training
                </h4></li>
                <li><h4 className="hero-paragraph">
                  Sexual harrasment training
                </h4></li>
                <li><h4 className="hero-paragraph">
                  Equal opportunity training
                </h4></li>
                <li><h4 className="hero-paragraph">
                  Sales Training
                </h4></li>
                <li><h4 className="hero-paragraph">
                  Partner and client training
                </h4></li>
                <li><h4 className="hero-paragraph">
                  Time management training
                </h4></li>
                </ul>
                <div className="form-group mt-lg-5 w-75 p-2 border-light border bg-white rounded-lg">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="form-group icon-input mb-0">
                        <i className="ti-search font-xs text-grey-400"></i>
                        <input
                          type="text"
                          className="style1-input bg-transparent border-0 pl-5 font-xsss mb-0 text-grey-500 fw-500"
                          placeholder="Search courses"
                        />
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group icon-input mb-0">
                        <i className="ti-package font-xs text-grey-400"></i>
                        <select className="style1-select bg-transparent border-0 pl-5">
                          <option value="">All Categories</option>
                          <option value="Bootstrap">HTML & CSS Design</option>
                          <option value="React">Professional JavaScript</option>
                          <option value="Backend">Backend Developer MySQL</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <a
                        href="/"
                        className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase "
                      >
                        Search
                      </a>
                    </div>
                  </div>
                </div>
                <h4 className="text-grey-400 font-xssss fw-500 ml-1 lh-24">
                  <b className="text-white">Popular Search :</b> JavaScript, HTML, CSS, MySQL
                </h4>
              </div>
            </div>
          </div>
        </section>
        
        <section className="brand-wrapper pt-5 pb-7">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Slider {...brandsettings}>
                  {brandList.map((value, index) => (
                    <div key={index} className="text-center">
                      <img
                        src={`assets/images/${value.bimg}`}
                        alt="avatar"
                        className="w100 ml-auto mr-auto"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section className="how-to-work pt-lg--5 pb-lg--7">
          <div className="container">
            <div className="row justify-content-center">
              <div className="page-title style1 col-xl-6 col-lg-8 col-md-10 text-center mb-5">
                <h2 className="text-grey-900 fw-700 display1-size display2-md-size pb-3 mb-0 d-block">
                  Turn-key services 
                </h2>
                <p className="fw-300 font-xsss lh-28 text-grey-500">
                  "The Everything Company: Driving innovation across diverse industries and shaping tomorrow's landscapes."
                </p>
              </div>
            </div>

            <section className="row">
              {iconList.map((value, index) => (
                <div className="col-lg-4" key={index}>
                  <div className="card p-4 border-0 text-center d-block">
                    <i
                      className={`btn-round-xxxl font-xxl position-relative z-index ${value.icon} ${value.color} ${value.bgcolor}`}
                    ></i>
                    <h2 className="font-xs fw-700 text-grey-900 mt-4 mb-2">
                      {value.title}
                    </h2>
                    <p className="fw-500 font-xssss lh-28 text-grey-500">
                      {value.des}
                    </p>
                  </div>
                </div>
              ))}
            </section>
          </div>
        </section>

        <section className="ready-made-courses__container container">
          <div className="ready-made-courses__text-wrapper">
            <h1 className="title_h2 ready-made-courses__title">
              Ready-made courses developed by professionals
            </h1>
            <p className="text ready-made-courses__text">
              Revitalize your teams with our enduring courses encompassing compliance, management, and highly sought-after topics. Available through subscription, all updates are included at no extra charge.
            </p>
            <div className="ready-made-courses__button-wrapper">
              <a href="/course-library" className="button button_white ready-made-courses__button js-get-free-trial-button">
                Learn more <span className="arrow">→</span>
              </a>
            </div>
          </div>
          <div className="ready-made-courses__img-wrapper">
            <div className="ready-made-courses__img-container">
              <img className="img-responsive ready-made-courses__img" src={PopularCourses} alt="" title=""/>
            </div>          
          </div>
        </section>


        <section className="how-to-work pb-lg--7 mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="page-title style1 col-xl-6 col-lg-6 col-md-10 text-center mb-5">
                <h2 className="text-grey-900 fw-700 display1-size display2-md-size pb-3 mb-0 d-block">
                  Popular courses
                </h2>
                <p className="fw-300 font-xsss lh-28 text-grey-500">
                  Variety of programming courses offered at tks360 LMS
                </p>
              </div>
            </div>

            <div className="row">
              {courseList.map((value, index) => (
                // Start Single Demo
                <div
                  className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4"
                  key={index}
                >
                  <div className="card course-card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1">
                    <div className="card-image w-100 mb-3">
                      <Link
                        to="/coursedetails"
                        className="video-bttn position-relative d-block"
                      >
                        <img
                          src={VideoThumbnail}
                          alt="course"
                          className="w-100"
                        />
                      </Link>
                    </div>
                    <div className="card-body pt-0">
                      <span
                        className={`font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 d-inline-block mr-1 ${value.status}`}
                      >
                        {value.tag}
                      </span>
                      <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                        <span className="font-xsssss">$</span> {value.price}
                      </span>
                      <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                        <Link
                          to="/coursedetails"
                          className="text-dark text-grey-900"
                        >
                          {value.title}
                        </Link>
                      </h4>
                      <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                        {value.lesson} Lesson
                      </h6>
                      <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                        <li>
                          <a href="/">
                            <img
                              src="assets/images/user.png"
                              alt="avatar"
                              className="w30 d-inline-block rounded-circle"
                            />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img
                              src="assets/images/user.png"
                              alt="avater"
                              className="w30 d-inline-block rounded-circle"
                            />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img
                              src="assets/images/user.png"
                              alt="avatar"
                              className="w30 d-inline-block rounded-circle"
                            />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img
                              src="assets/images/user.png"
                              alt="avatar"
                              className="w30 d-inline-block rounded-circle"
                            />
                          </a>
                        </li>
                        <li className="last-member">
                          <a
                            href="/"
                            className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center"
                          >
                            +2
                          </a>
                        </li>
                        <li className="pl-4 w-auto">
                          <a
                            href="/"
                            className="fw-500 text-grey-500 font-xssss"
                          >
                            Student apply
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                // End Single Demo
              ))}
            </div>
          </div>
        </section>
        
        {/**Hire Us Section */}
        <section id="hire" className='hire-us section'>
          <div className='hire-us-sec'>
            <div className='hire-us-block'>
              <h1 className='hire-us-hdr'>Hire Us</h1>
              <p className='hire-us-subtxt'>
                Whatever your goal - we will get you there.
              </p>

              <br />
              <hr className='hire-us-hr' />
              <br />

              <p className="hire-us-txt">
                <em>"I love tks360! I love the ease of use, I love the fact that I have total creative freedom..."</em>
              </p>

              <p className="charlotte">Jessica Rivera</p>
              <p className="slack">Head of Commercials, Slack</p>

              <p className="hire-us-txt2">
                <em>"It's beautiful and the coding is done quickly and seamlessly. Keep it up!"</em>
              </p>

              <p className="james">James Austin</p>
              <p className="hubspot2">Executive Creative Director, HubSpot</p>

              <div className="people">
                <img className="two-people three-people" src="../assets/images/avatar4.png" alt="" />
                <img className="two-people fourth-person" src="./assets/people/man_one.jpg" alt="" />
                <img className="one-person three-people" src="./assets/people/woman_two.jpg" alt="" />
              </div>
            </div>
            
            <div className="form-container">
              <form className="form" action="/action_page.php">
                <p className="fill">
                  Fill out the form and we'll be in touch as soon as possible.
                </p>

                <div className="fill-out">
                  <label for="fname"></label><br />
                  <input className="fname" type="text" id="fname" name="fname" value="First name" /><br />

                  <label for="lname"></label><br />
                  <input class="lname" type="text" id="lname" name="lname" value="Last name" />
                </div>

                <div class="fill-out">
                  <label for="email"></label><br />
                  <input class="email" type="text" id="lname" name="email" value="Work email" />

                  <label for="company"></label><br />
                  <input class="lname" type="text" id="lname" name="company" value="Company website" /><br /><br />
                </div>

                <div class="fill-out">
                  <label for="Budget"></label>
                  <select class="budget" name="budget" id="budget">
                    <option value="budget">Budget</option>
                  </select>

                  <label for="Country"></label>
                  <select class="budget" name="country" id="budget">
                    <option value="country">Country</option>
                    <option value="usa">USA</option>
                    <option value="italy">Italy</option>
                    <option value="germany">Germany</option>
                  </select>
                </div>

                <textarea class="text-area" name="" id="" cols="28" rows="4" placeholder="Tell us more about your project, needs, and timeline."></textarea>

                <div class="marketing">
                  <input class="checkbox" type="checkbox" />
                  <p class="emails">
                    Yes, I'd like to receive occasional marketing emails from Front.
                    I have the right to opt out at any time.
                    <span class="policy">View privacy policy.</span>
                  </p>
                </div>

                <div class="submit-btn">
                  <input class="submit" type="submit" value="Get Started" />
                </div>
              </form>
            </div>
          </div>
        </section>


        <Footer />
        <ComingSoon /> 
      </>
    );
  }

export default Home;
