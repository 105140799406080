

const AdminFooter =() => {
  return (
    <div className="lower-footer pb-3">
      <div className="container px-3">
        <div className="row">
          <div className="col-md-6 text-center text-sm-left xs-mb-3">
            <p className="text-grey-500 fw-500 font-xssss mb-0">
              @ Copyright 2023 All rights reserved.
            </p>
          </div>
          <div className="col-md-6 text-center text-sm-right">
            <img src="assets/images/payments.jpg" alt="payment" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminFooter;
