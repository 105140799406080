import React from 'react';
import '../assets/css/styles.css';

const FilterBar = () => {
  const handleAddFilterChange = (e) => {
    console.log(`Add Filter clicked: ${e.target.value}`);
  };

  const handleEnrollmentTypeChange = (e) => {
    console.log(`Enrollment type selected: ${e.target.value}`);
  };

  const handleLearningItemNameChange = (e) => {
    console.log(`Learning Item Name selected: ${e.target.value}`);
  };

  const handleStartDateChange = (e) => {
    console.log(`Start Date selected: ${e.target.value}`);
  };

  return (
    <div className="filter-bar">
      <select className="filter-button" onChange={handleAddFilterChange}>
        <option iconClass= 'feather-calendar' placeholder='Add Filter'>Add Filter</option>
        <option iconClass= 'feather-calendar' value='add'>Completion Date</option>
        <option iconClass= 'feather-calendar' value='add'>Due Date</option>
        <option iconClass= 'feather-calendar' value='add'>Last Login</option>
        <option iconClass= 'feather-calendar' value='add'>User Creation Date</option>
        <option iconClass= 'feather-calendar' value='add'>Department</option>
        <option iconClass= 'feather-calendar' value='add'>Group Name</option>
        <option iconClass= 'feather-calendar' value='add'>User Role</option>
        <option iconClass= 'feather-calendar' value='add'>User Status</option>
        <option iconClass= 'feather-calendar' value='add'>Supervisor</option>
        <option iconClass= 'feather-calendar' value='add'>Functional Manager</option>
        <option iconClass= 'feather-calendar' value='add'>Firts Name</option>
        <option iconClass= 'feather-calendar' value='add'>Last Name</option>
        <option iconClass= 'feather-calendar' value='add'>Login</option>
        <option iconClass= 'feather-calendar' value='add'>Email</option>
        <option iconClass= 'feather-calendar' value='add'>Phone</option>
        <option iconClass= 'feather-calendar' value='add'>Job Title</option>
        <option iconClass= 'feather-calendar' value='add'>Country</option>
        {/* Additional options */}
      </select>

      <select className="filter-dropdown" onChange={handleEnrollmentTypeChange}>
        <option value="all">Enrollment type: All</option>
        <option iconClass= 'feather-calendar' value='add'>Enrolled by Admin</option>
        <option iconClass= 'feather-calendar' value='add'>Added from Catalog</option>
        {/* Additional options */}   
      </select>
           {/* need api from courses to display here */}
      <select className="filter-dropdown" onChange={handleLearningItemNameChange}>
        <option value="all">Learning Item Name: All</option>
        {/* Additional options */}
      </select>
          
      <select className="filter-dropdown" onChange={handleStartDateChange}>
        <option value="90days">Start Date: Last 90 days</option>
        {/* Additional options */}
      </select>
    </div>
  );
};

export default FilterBar;

