import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.scss';
import './index.css'
import App from './App.jsx'
//import comingSoon from './components/comingSoon.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'))
    root.render (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );



