import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Slider from 'react-slick';

//images istock trial
import OfficeWorkers from '../../assets/images/istocktrial/office-workers.jpg'
import Subscription from '../../assets/images/istocktrial/subscription.jpg'

//styles
import '../../assets/css/styles.css'
import '../../assets/css/default.css'


const brandList = [
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
  { bimg: 'b-1.png' },
];
const feedbackList = [
  {
    imageUrl: './assets/images/1.png',
    name: 'Goria Coast',
    status: 'Digital Marketing Executive',
    des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold.',
  },
  {
    imageUrl: 'user.png',
    name: 'Thomas Smith',
    status: 'Digital Marketing Executive',
    des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold.',
  },
  {
    imageUrl: 'user.png',
    name: 'Hurin Seary',
    status: 'Digital Marketing Executive',
    des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold.',
  },
  {
    imageUrl: 'user.png',
    name: 'Goria Coast',
    status: 'Digital Marketing Executive',
    des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold.',
  },
  {
    imageUrl: 'user.png',
    name: 'Thomas Smith',
    status: 'Digital Marketing Executive',
    des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold.',
  },
  {
    imageUrl: 'user.png',
    name: 'Hurin Seary',
    status: 'Digital Marketing Executive',
    des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold.',
  },
];

const About = () => {
    const brandsettings = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 6,
      centerMode: false,
      variableWidth: false,
      autoplay: true,
    };
    const feedbacksettings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      centerMode: false,
      variableWidth: false,
    };

    return (
    <>
      <Header />

      <section className="bg-header-wrapper">
        <div className="container">
          <div className="hero-wrapper">
            <h2 className="hero-header">
              Developing web-based <br /> training solutions to <br /> foster individual advancement
            </h2>
            <p className='hero-paragraph'>  
              We're tks360, a tech firm headquartered in Edmond, Oklahoma. With a decade of experience, <br /> we specialize in crafting eLearning tools that empower individuals, teams, <br /> and enterprises to thrive via comprehensive employee training and growth.
            </p>
            <a className="btn btn-white" href="Contact">Learn More</a>
          </div>
        </div>
      </section>

      <section className="about-wrapper pb-lg--7 pt-lg--7 pt-5 pb-7">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <h2 className="text-grey-900 fw-700 display1-size display2-md-size pb-2 mb-0 mt-3 d-block lh-3">         
                Select the subscription <br /> that best fits your LMS needs.
              </h2>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 text-center">
              <h4 className=" fw-500 mb-4 lh-30 font-xsss text-grey-500 mt-0">
              This LMS stands out as the top choice among our LMS purchases. Its unparalleled customization options and remarkable flexibility elevate its performance. The incredible speed further enhances its excellence.
              </h4>
            </div>
            <div className="col-lg-12 mt-3">
              <a
                href="/about"
                className="video-bttn"
                data-toggle="modal"
                data-target="#Modalvideo"
              >
                <img
                  src={Subscription}
                  alt="about"
                  className="img-fluid rounded-lg center"
                />
              </a>
            </div>
            <div className="col-lg-12 mt-5 text-center pt-3">
              <a
                href="/about"
                className="ml-1 mr-1 rounded-lg text-primary font-xss border-size-md border-primary fw-600 open-font p-3 w200 btn md-mb-2 mt-3"
              >
                Learn More
              </a>
              <h3 className="font-xss fw-600 text-grey-500 p-3 d-inline-block d-none-xs">
                or
              </h3>
              <a
                href="/contact"
                className="ml-1 mr-1 rounded-lg alert-primary text-primary font-xss border-size-md border-0 fw-600 open-font p-3 w200 btn"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="how-to-work">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-4">
              <img
                src={OfficeWorkers}
                alt="about"
                className="rounded-lg img-fluid shadow-xs"
              />
            </div>
            <div className="col-lg-6 offset-lg-1 page-title style1">
              <h2 className="fw-700 text-grey-800 display1-size display2-md-size lh-3 pt-lg--5">
                Easily recharge online and conveniently pay your monthly bills.
              </h2>
              <p className="font-xsss fw-400 text-grey-500 lh-28 mt-0 mb-0  mt-3 w-75 w-xs-90">
                Experience hassle-free online recharging and simplified monthly bill payments. Manage your expenses effortlessly, ensuring your connectivity and utilities are always up-to-date. Explore our user-friendly platform designed to streamline your recharges and make managing recurring payments a breeze.
              </p>

              <h4 className="fw-600 font-xs mt-5 mb-2">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border"></i>
                Choose what to do
              </h4>
              <p className="fw-300 font-xsss lh-28 text-grey-500 mt-0 ml-4 pl-3 w-75 w-xs-90">
                Pick from one of our many courses available.
              </p>

              <h4 className="fw-600 font-xs mt-4 mb-2">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border"></i>
                Find what you want
              </h4>
              <p className="fw-300 font-xsss lh-28 text-grey-500 mt-0 ml-4 pl-3 w-75 w-xs-90">
                Search and filter through tons of courses, read reviews, explore
                photos and find the perfect course.
              </p>

              <h4 className="fw-600 font-xs mt-4 mb-2">
                <i className="ti-check btn-round-xs text-white bg-success mr-2 border"></i>
                Explore amazing code
              </h4>
              <p className="fw-300 font-xsss lh-28 text-grey-500 mt-0 ml-4 pl-3 w-75 w-xs-90">
                Courses in their Web Development path, starting from basics to more advanced concepts.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="popular-wrapper pb-0 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-left mb-3 pb-0">
              <h2 className="text-grey-800 fw-700 font-xl lh-2">
                Customers love what we have to offer
              </h2>
            </div>

            <div className="col-lg-12 p-0">
              <Slider {...feedbacksettings}>
                {feedbackList.map((value, index) => (
                  <div key={index} className="text-center py-4 px-3">
                    <div className="card w-100 p-5 text-left border-0 shadow-xss rounded-lg">
                      <div className="card-body pl-0 pt-0">
                        <img
                          src={`assets/images/${value.imageUrl}`}
                          alt="user"
                          className="w45 float-left mr-3"
                        />
                        <h4 className="text-grey-900 fw-700 font-xsss mt-0 pt-1">
                          {value.name}
                        </h4>
                        <h5 className="font-xssss fw-500 mb-1 text-grey-500">
                          {value.status}
                        </h5>
                      </div>
                      <p className="font-xsss fw-400 text-grey-500 lh-28 mt-0 mb-0 ">
                        {value.des}
                      </p>
                      <div className="star d-block w-100 text-right mt-4 mb-0">
                        <img
                          src="assets/images/star.png"
                          alt="star"
                          className="w15 mr-1 float-left mr-2"
                        />
                        <img
                          src="assets/images/star.png"
                          alt="star"
                          className="w15 mr-1 float-left mr-2"
                        />
                        <img
                          src="assets/images/star.png"
                          alt="star"
                          className="w15 mr-1 float-left mr-2"
                        />
                        <img
                          src="assets/images/star.png"
                          alt="star"
                          className="w15 mr-1 float-left mr-2"
                        />
                        <img
                          src="assets/images/star.png"
                          alt="star"
                          className="w15 mr-1 float-left mr-2"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="brand-wrapper pb-lg--7 pt-lg--7 pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Slider {...brandsettings}>
                {brandList.map((value, index) => (
                  <div key={index}>
                    <img
                      src={`assets/images/${value.bimg}`}
                      alt="avater"
                      className="w100"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <Footer bgColor="bg-dark" />
    </>
  );
}

export default About;
