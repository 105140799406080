import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import AdminSidebar from '../../components/AdminSidebar';
import AdminTopNav from '../../components/AdminTopNav';
import AdminFooter from '../../components/AdminFooter';
import Pagination from '../../components/Pagination';

const orderlList = [
  {
    id: '0901',
    name: 'Marvin McKinney',
    email: 'marvin@example.com',
    total: '$9.00',
    color: 'warning',
    status: 'Pending',
    date: '03.12.2020',
  },
  {
    id: '2323',
    name: 'Leslie Alexander',
    email: 'leslie@example.com',
    total: '$46.61',
    color: 'warning',
    status: 'Pending',
    date: '21.02.2020',
  },
  {
    id: '1233',
    name: 'Esther Howard',
    email: 'esther@example.com',
    total: '$12.00',
    color: 'danger',
    status: 'Canceled',
    date: '03.07.2020',
  },
  {
    id: '1233',
    name: 'Esther Howard',
    email: 'esther@example.com',
    total: '$12.00',
    color: 'danger',
    status: 'Canceled',
    date: '03.07.2020',
  },
  {
    id: '2323',
    name: 'Jenny Wilson',
    email: 'jenny@example.com',
    total: '$589.99',
    color: 'success',
    status: 'Received',
    date: '22.05.2020',
  },
  {
    id: '0901',
    name: 'Marvin McKinney',
    email: 'marvin@example.com',
    total: '$9.00',
    color: 'success',
    status: 'Received',
    date: '03.12.2020',
  },
  {
    id: '2323',
    name: 'Leslie Alexander',
    email: 'leslie@example.com',
    total: '$46.61',
    color: 'success',
    status: 'Received',
    date: '21.02.2020',
  },
  {
    id: '1233',
    name: 'Esther Howard',
    email: 'esther@example.com',
    total: '$12.00',
    color: 'success',
    status: 'Received',
    date: '03.07.2020',
  },
  {
    id: '1233',
    name: 'Esther Howard',
    email: 'esther@example.com',
    total: '$12.00',
    color: 'success',
    status: 'Received',
    date: '03.07.2020',
  },
  {
    id: '2323',
    name: 'Jenny Wilson',
    email: 'jenny@example.com',
    total: '$589.99',
    color: 'success',
    status: 'Received',
    date: '22.05.2020',
  },
];

const AdminOrder = () => {
  const [location, setLocation] = useState(false);

  const handleModel = () => {
    setLocation(prevLocation => !prevLocation);
  };

  return (
    <>
      <div id="wrapper">
        <AdminSidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopNav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex p-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Order List
                      </h4>
                      <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select>
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0">ID</th>
                              <th className="border-0" scope="col">
                                Name
                              </th>
                              <th className="border-0" scope="col">
                                Email
                              </th>
                              <th className="border-0" scope="col">
                                Total
                              </th>
                              <th className="border-0" scope="col">
                                Status
                              </th>
                              <th className="border-0" scope="col">
                                Date
                              </th>
                              <th scope="col" className="text-end border-0">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderlList.map((value, index) => (
                              <tr key={index}>
                                <td>{value.id}</td>
                                <td>
                                  <b>{value.name}</b>
                                </td>
                                <td>{value.email}</td>
                                <td>{value.total}</td>
                                <td>
                                  <span
                                    className={`badge rounded-pill font-xsssss fw-700 pr-3 pl-3 lh-24 text-uppercase rounded-3 ls-2 alert-${value.color}`}
                                  >
                                    {value.status}
                                  </span>
                                </td>
                                <td>{value.date}</td>
                                <td className="product-remove text-end">
                                  <a href="/admin-orderview">
                                    <i className="feather-edit me-1 font-xs text-grey-500"></i>
                                  </a>
                                  <Button
                                    className="bg-transparent border-0"
                                    onClick={() => {
                                      handleModel();
                                    }}
                                  >
                                    <i className="ti-trash  font-xs text-danger"></i>
                                  </Button>
                                  <Modal
                                    {...this.props}
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={location}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleModel();
                                      }}
                                      className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"
                                    ></Button>
                                    <Modal.Body className="text-center p-4">
                                      <i className="ti-info-alt text-warning display4-size"></i>
                                      <p className="text-grey-500 font-xsss mt-3 mb-4">
                                        Are you sure you want to delete
                                        product?
                                      </p>

                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-white bg-danger font-xssss text-uppercase fw-600 ls-3"
                                      >
                                        Yes, delete!
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-grey-600 bg-greylight font-xssss text-uppercase fw-600 ls-3 ms-1"
                                      >
                                        No, cancel!
                                      </Button>
                                    </Modal.Body>
                                  </Modal>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination divClass="pagination justify-content-center mt-5" />
                </div>
              </div>
            </div>

            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminOrder;
