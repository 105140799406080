import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateCourseButton = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courseName, setCourseName] = useState('');

  const handleChange = (event) => {
    setCourseName(event.target.value);
  };

  const createNewCourse = () => {
    setIsLoading(true);

    // Simulated API call delay (replace with your actual API call)
    setTimeout(() => {
      setIsLoading(false);

      // Example alteration of course name before redirection
      const alteredCourseName = `Altered_${courseName}`;

      // Redirect to the new course page with the altered course name in the URL
      navigate(`/admin-newproject/?name=${encodeURIComponent(alteredCourseName)}`);
    }, 2000); // Simulating a 2-second delay, replace with your actual API call duration
  };

  return (
    <div className='bg-slate-400'>
      <input
        type='text'
        placeholder='Input Course Name'
        id='courseName'
        name='courseName'
        onChange={handleChange}
        value={courseName} // Bind value to courseName state
        className='font-md'
      />
      
      <button className='font-md bg-slate-400' onClick={createNewCourse} disabled={isLoading}>
        {isLoading ? 'Creating...' : `Create ${courseName}`}
      </button>
    </div>
  );
};

export default CreateCourseButton;
