import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CreateFolderPopup = ({ show, handleClose, handleCreate }) => {
  const [folderName, setFolderName] = useState('');

  const handleInputChange = (event) => {
    setFolderName(event.target.value);
  };

  const handleCreateFolder = () => {
    // Pass folderName to the parent component for folder creation
    handleCreate(folderName);
    // Reset the folder name after creation
    setFolderName('');
    // Close the modal
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Folder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="folderName">Folder Name:</label>
          <input
            type="text"
            className="form-control"
            id="folderName"
            value={folderName}
            onChange={handleInputChange}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCreateFolder}>
          Create Folder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateFolderPopup;
